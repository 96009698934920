<template>
    <div class="main content-padding" :key="loopKey">
        <div class="main__body">
            <div class="row">
                <div class="col-12 mb-4">
                    <LabelInput
                        font-family="'Oswald', sans-serif"
                        :base-card="true"
                        :label="labels.title"
                        :value="cardData.title"
                        @changed="dataChanged( { value: $event, key: 'title' } )"
                    />
                </div>
                <div class="col-lg mb-4">
                    <TextEditorCard
                        :title="labels.question"
                        :value="cardData.content_0"
                        @changed="dataChanged( { value: $event.value, key: 'content_0' } )"
                    />
                </div>
                <div class="col-lg mb-4">
                    <TextEditorCard
                        :title="labels.answer"
                        :value="cardData.content_1"
                        @changed="dataChanged( { value: $event.value, key: 'content_1' } )"
                    />
                </div>
                <div class="col-12 mb-4">
                    <LinkEntityCard
                        :title="labels.related_package"
                        :entity-title="relatedTitle"
                        :link="relatedLink"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextEditorCard from "@/components/Input/TextEditorCard";
import LabelInput from "@/components/Input/LabelInput";
import LinkEntityCard from "@/components/Cards/LinkEntityCard";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "Main",
    components: {
        TextEditorCard,
        LabelInput,
        LinkEntityCard,
    },
    data() {
        return {
            loopKey: Date.now(),
            packages: {
                title: "Related Packages",
                value: "Lorem ipsum dolor sit amet"
            }
        };
    },
    computed: {
        ...mapGetters('helper', ['labels']),
        pageHeaderData() {
            let output = {
                preTitle: this.labels.card_title,
                title: this.cardTitle,
                btnList: [
                    {
                        type: "button",
                        label: this.labels.save,
                        variant: "btn-green",
                        class: "btn-type-button",
                        action: this.saveCard,
                        disabled: this.countChanges.saveBtnDisabled
                    }
                ]
            };
            return output;
        },
        countChanges() {
            return this.$store.getters['card/countCardChanges'];
        },
        stateCardData() {
            return this.$store.state.card.stateCardData
        },
        cardData() {
            return this.$store.state.card.cardData
        },
        relatedTitle() {
            let output = '';
            if ( this.stateCardData.modules ) {
                this.stateCardData.modules.forEach( item => {
                    if ( item._id === this.stateCardData.related ) output = item.title;
                } );
            }
            return output;
        },
        relatedLink() {
            if ( this.stateCardData.related ) {
                return { name: 'module-editor', params: { id: this.stateCardData.related } }
            }
            return { name: 'module-editor' };
        },
        cardTitle() {
            if ( this.stateCardData.title ) return this.stateCardData.title;
            return '';
        },
    },
    methods: {
        ...mapActions('notification', {
            addNotification: 'addNotification'
        }),
        ...mapMutations( "helper", {
            setPageHeaderData: "setPageHeaderData",
        }),
        ...mapMutations( "card", {
            updateCardData: "updateCardData",
            clearCardData: 'clearCardData',
        }),
        ...mapActions( "card", {
            getSingle: "getSingle",
            update: 'update',
        }),

        dataChanged( data ) {
            this.updateCardData( { key: data.key, value: data.value } );
        },

        getCardData() {
            this.getSingle( { id: this.$route.params.id } )
            .then( res => {
                if ( res.success ) {
                    this.loopKey = Date.now();
                } else if (res.error) {
                    this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                }
            } )
            .catch( err => console.log( err ) );
        },

        saveCard() {
            let data = {
                id: this.$route.params.id,
            }
            this.countChanges.changedKeys.forEach( key => {
                data[key] = this.cardData[key];
            } )
            this.update( data )
            .then( res => {
                if (res.success) {
                    console.log(res)
                    this.addNotification( { variant: 'success', msg: ['saved'], labels: this.labels })
                    this.getCardData();
                } else if (res.error) {
                    this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                }
            })
            .catch( err => console.log( err ) );
        },
    },
    beforeMount() {
        this.setPageHeaderData({ preTitle: "", title: "", btnList: [] });
    },
    mounted() {
        this.getCardData();
        this.setPageHeaderData(this.pageHeaderData);
    },
    beforeDestroy() {
        this.setPageHeaderData({});
        this.clearCardData();
    },
    watch: {
        pageHeaderData(value) {
            this.setPageHeaderData(value);
        }
    },
    beforeRouteLeave (to, from, next) {
        if( !this.countChanges.saveBtnDisabled ){
            const answer = window.confirm( this.labels.do_you_really_want_to_leave_you_have_unsaved_changes );
            if (answer) {
                // RESET CHANGES
                next()
            } else {
                next(false);
            }
        } else {
            next();
        }
    },
};
</script>

<style lang="scss" scoped></style>