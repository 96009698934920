<template>
    <BaseCard class="link-entity-card" :padding="padding">
        <h6 class="d-inline">{{ title }}: </h6>
        <span>{{ entityTitle }}</span>
        <router-link :to="link">
            <font-awesome-icon :icon="icon"/>
        </router-link>
    </BaseCard>
</template>

<script>
import BaseCard from '@/components/Cards/BaseCard.vue';

import { library } from "@fortawesome/fontawesome-svg-core";
import { customExternalLink } from "@/assets/icons";
library.add(customExternalLink);

export default {
    name: "LinkEntityCard",
    components: {
        BaseCard,
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        entityTitle: {
            type: String,
            default: ''
        },
        link: {
            type: [String, Object],
            default: ''
        },
        icon: {
            type: String,
            default: 'custom-external-link'
        },
        padding: {
            type: String,
            default: '2rem 1.5rem'
        },
    },
}
</script>

<style lang="scss" scoped>

.link-entity-card {

    h6 {
        font-family: "Oswald", sans-serif;
        line-height: 1.5;
        font-size: 1rem;
        font-weight: 400;
    }

    span {
        margin-left: 1rem;
        font-size: .925rem;
    }

    a {
        margin-left: 2rem;
        color: $light_text;
        cursor: pointer;
    }
}

</style>